import auth from './auth'
import hero from './hero'
import saved from './saved'
import about from './about'
import brands from './brands'
import navbar from './navbar'
import footer from './footer'
import profile from './profile'
import catalogs from './catalogs'
import contacts from './contacts'
import notFound from './notFound'
import companyNews from './companyNews'
import searchResults from './searchResults'
import catalogSearch from './catalogSearch'

const TranslationUZ = {
  auth,
  hero,
  saved,
  about,
  navbar,
  brands,
  footer,
  profile,
  catalogs,
  contacts,
  notFound,
  companyNews,
  searchResults,
  catalogSearch,
}

export default TranslationUZ