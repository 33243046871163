export default {
  heading: 'О компании',
  videoHeading: 'Для запуска видео нажать на «Воспроизведение»',
  videoTitle: 'Видео о сайте Abrormarket.uz',
  aboutCompany: `<strong>«Abrormarket.uz»</strong> — это отдел продаж 30 фабрик. Эти заводы производят пластиковые бутылки, колпачки, триггеры, помпы, спреи и другие изделия из пластика. <b>«Abrormarket.uz» </b> — уникальная производственная компания Узбекистана, предлагающая полный комплекс услуг по производству и декорированию пластиковой упаковки для парфюмерной, косметической, химической, автохимической и медицинской промышленности. Более 17 лет компания демонстрирует постоянный рост и развитие, инновации в технологиях и дизайнерских решениях.
  <b>«Abrormarket.uz» </b> — одна из хорошо известных торговых площадок в Узбекистане и Центральной Азии по продаже пластиковых бутылок, колпачков, спреев, триггеров и других пластиковых изделий.`,
  weProduce: `
  <li>
    <a href='/catalog/jars-for-cosmetics'>Косметические флаконы </a> из ПЭ, ПП, ПЭТ и ПЭВП объемом 15-10000 мл, с эффектом soft-touch;
  </li>
  <li>
    <a href='/catalog/packaging-for-household-chemicals'>Бутылки и баночки для бытовой и профессиональной химии, </a> автокосметики объемом от 100 до 3000 мл;
  </li>
  <li>
    <a href='/catalog/packaging-for-medicine'>Упаковка для медицинской промышленности </a> объемом от 4 до 250 мл;
  </li>
  <li>
    <a href='/catalog/caps-and-shoulders-for-tubes'>Крышки с откидной и дисковой крышкой для бутылок </a> - стандартное горлышко 24/410 DIN, 28/410 DIN, 20/415 DIN;
  </li>
  <li>
    <span>Упаковка для гостиничного бизнеса </span> - бутылки ПЭТ и ПНД объемом 15-30 мл;
  </li>
  <li>
    Дополнительно: <a href='/catalog/accessories-for-bottles'>комплектуем флаконы </a> различными крышками: триггерами, спреями, дозаторами и т.д.;
  </li>
`,
  weAdvantages: `
  <li>
    <strong>Огромный опыт </strong> – Более 17 лет (2006 год) Abrormarket.uz создает красивую и качественную упаковку для вашего продукта;
  </li>
  <li>
    <strong>Мощная инструментальная база </strong> – практически все формы изготавливаются собственными силами, что является огромным преимуществом за счет оптимальных сроков запуска новых проектов;
  </li>
  <li>
    <strong>Самый широкий спектр технологий переработки пластмасс: </strong> литье под давлением, в том числе двухцветное впрыскивание на флип-топ колпачки, выдувное формование, литьевое выдувное формование для ПП и ПЭТ;
  </li>
  <li>
    <strong>Abrormarket.uz </strong> работает с самым широким спектром сырья, такого как ПП, ПЭ, ПЭТ, ПНД;
  </li>
  <li>
    <strong>Abrormarket.uz </strong> обеспечивает контроль качества на каждом этапе производственного процесса;
  </li>
`,
  produceAboutHeading: 'Мы производим',
  advantagesAboutHeading: 'Наши возможности и преимущества',
  ourPartners: 'Наши партнеры',
}
