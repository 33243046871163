// Types
import { IRoute } from 'types'

// Layouts
import MainLayout from 'layouts/MainLayout'

// Auth Pages
import { Login, Register, ForgotPassword } from 'pages/auth'

// Pages
import { Home, About, Likes, Catalog, Catalogs, Contacts, News, NotFound, ProductView, SearchResult, NewsView, OrderedProducts, SavedProducts, ForOrders, Brands, Brand, CatalogsMobileVersion, Profile } from 'pages'

// Constants for route paths
const ROUTE_HOME = '/'
const ROUTE_NEWS = '/news'
const ROUTE_NOT_FOUND = '*'
const ROUTE_LOGIN = '/login'
const ROUTE_ABOUT = '/about'
const ROUTE_BRANDS = '/brands'
const ROUTE_PROFILE = '/profile'
const ROUTE_CATALOG = '/catalog'
const ROUTE_BRAND = '/brands/:id'
const ROUTE_NEWS_ID = '/news/:id'
const ROUTE_USER_LIKES = '/likes'
const ROUTE_REGISTER = '/register'
const ROUTE_CONTACTS = '/contacts'
const ROUTE_FOR_ORDERS = '/for-orders'
const ROUTE_CATALOG_ID = '/catalog/:id'
const ROUTE_CATALOG_MOBILE = '/catalog-list'
const ROUTE_SEARCH_RESULT = '/search-result'
const ROUTE_SAVED_PRODUCTS = '/savedProducts'
const ROUTE_FORGOT_PASSWORD = '/forgot-password'
const ROUTE_ORDERED_PRODUCTS = '/orderedProducts'
const ROUTE_CATALOG_ID_PRODUCT_ID = '/catalog/:id/:id'

// Generate routes based on authentication status
const generateRoutes = (authed: boolean): IRoute[] => {
  const routes: IRoute[] = [
    {
      path: ROUTE_HOME,
      element: <MainLayout />,
      children: [
        { path: ROUTE_HOME, element: <Home /> },
        { path: ROUTE_NEWS, element: <News /> },
        { path: ROUTE_ABOUT, element: <About /> },
        { path: ROUTE_BRAND, element: <Brand /> },
        { path: ROUTE_BRANDS, element: <Brands /> },
        { path: ROUTE_NEWS_ID, element: <NewsView /> },
        { path: ROUTE_CATALOG, element: <Catalogs /> },
        { path: ROUTE_CONTACTS, element: <Contacts /> },
        { path: ROUTE_CATALOG_ID, element: <Catalog /> },
        { path: ROUTE_NOT_FOUND, element: <NotFound /> },
        { path: ROUTE_FOR_ORDERS, element: <ForOrders /> },
        { path: ROUTE_SEARCH_RESULT, element: <SearchResult /> },
        { path: ROUTE_CATALOG_ID_PRODUCT_ID, element: <ProductView /> },
        { path: ROUTE_CATALOG_MOBILE, element: <CatalogsMobileVersion /> },
      ],
    },
  ]

  // Add authentication-dependent routes
  if (!authed) {
    routes.unshift({ path: ROUTE_LOGIN, element: <Login /> }, { path: ROUTE_REGISTER, element: <Register /> }, { path: ROUTE_FORGOT_PASSWORD, element: <ForgotPassword /> })
  } else {
    routes?.[0]?.children?.push(
      { path: ROUTE_PROFILE, element: <Profile /> },
      { path: ROUTE_USER_LIKES, element: <Likes /> },
      { path: ROUTE_SAVED_PRODUCTS, element: <SavedProducts /> },
      { path: ROUTE_ORDERED_PRODUCTS, element: <OrderedProducts /> },
    )
  }

  return routes
}

export default generateRoutes