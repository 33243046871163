export default {
  title: 'Qidiruv natijalari',
  heading: 'Qidiruv natijalari',
  resultsNotFound: 'Tanlangan parametrlar boʻyicha natijalar yoʻq!',
  brand: 'Brend',
  manufacturer: 'Ishlab chiqaruvchi',
  standard: 'Tomoq standarti',
  height: 'Balandligi',
  diameter: 'Diametri',
  volume: 'Volumetrik',
  package: 'Qadoq',
  category: 'Kategoriya',
  color: 'Tur',
  gram: 'Gramm',
  price: 'Narxi',
}