export default {
  title: 'Результаты поиска',
  heading: 'Результаты поиска',
  resultsNotFound: 'Нет результатов по выбранным параметрам!',
  brand: 'Бренд',
  manufacturer: 'Производитель',
  standard: 'Стандарт горла',
  height: 'Высота',
  diameter: 'Диаметр',
  volume: 'Объём',
  package: 'Упаковка',
  category: 'Категория',
  color: 'Тип',
  gram: 'Грамм',
  price: 'Цена',
}