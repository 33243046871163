export default {
  heading: 'Saqlangan mahsulotlar',
  heading2: 'Buyurtma qilingan mahsulotlar',
  heading3: 'Sizga yoqqan mahsulotlar',
  button: "Saqlangan mahsulotlar bo'yicha ariza qoldirish",
  button2: "Saqlangan mahsulotlarni o'chirish",
  button3: "Buyurtma qilingan mahsulotlarni o'chirish",
  deletedProducts: " Savatingizga qo'shgan 2 ta mahsulot(lar)ingiz saytdan olib tashlandi.",
  deletedProductsUZB1: "Savatingizga qo'shgan ",
  deletedProductsUZB2: " ta mahsulot(lar)ingiz saytdan olib tashlandi.",
  deletedOrderedProductsUZB1: "Siz buyurtma bergan ",
  deletedOrderedProductsUZB2: " ta mahsulot(lar)ingiz saytdan olib tashlandi.",
} 