export default {
  heading: 'Contacts',
  phone: 'Phones',
  phone2: 'Phone',
  address: 'Address',
  addressHeading: 'Representative office in Uzbekistan',
  contacHeading: 'Production in Russia',
  addressName: 'Yakkasaroy district, Shota Rustaveli 1, 1st',
  feedback: 'FEEDBACK',
  feedback2: 'Application form',
  name: 'Name',
  surname: 'Surname',
  mail: 'Email',
  message: 'Message',
  password: 'Password',
  messagePlaceholder: 'Enter your message...',
  submit: 'Send message',
  submit2: 'Send',
  submit3: 'Send a request',
  save: 'Save',
  saveAll: 'Save all',
  sendConfirm: 'Get confirm password',
  saveEmail: 'Save new email',
  savePhone: 'Save new phone number',
  currPass: 'Current Password',
  newPass: 'New Password',
  confirmPass: 'Confirm Password',
  logout: 'Sign Out',
  deleteAcc: 'Delete Profile',
  errorMessage: 'Error, please try again later!',
  actions: 'Actions',
  changeEmail: 'Change email',
  verifyEmail: 'Confirm email',
  changePhone: 'Change phone',
  verifyPhone: 'Confirm phone',
  verifyCode: 'Confirmation code',
  generalInfo: 'General information',
  changeLoginPass: 'Change login password',
  enterNewPass: 'Enter a new password',
  errConfirmPass: 'The verification password is not the same!',
  delAccSure1: 'Are you sure?',
  delAccSure2: 'Not, no sure.',
  delAccSure3: 'Yes, sure!',  
  delAccSure4: 'A confirmation code to delete your profile has been sent to your email. If you enter the sent code and send it, your profile will be deleted completely.',
  forgotPass1: 'Enter your account email to reset your password.',
  errorMessage2: 'The confirmation code is incorrect!',
}