export default {
  // Globals
  headerPageTitle: 'Главная',
  viewModel: 'Посмотреть 3D-модель',
  addToCart: 'Добавить в корзину',
  openCartSettings: 'Открыть настройки добавления в корзину',
  closeCartSettings: 'Закрыть настройки добавления в корзину',
  removeToCart: 'Удалить из корзины',
  nothingHere: 'Здесь ничего нет...',
  // For navbar
  heading: 'Производство пластиковой упаковки',
  contactsSelect: 'Свяжитесь с нами',
  about: 'О компании',
  catalog: 'Каталог',
  press: 'Пресс-формы',
  pages: 'Страницы',
  categories: 'Категории',
  cart: 'Корзина',
  contact: 'Контакты',
  brands: 'Бренды',
  forOrders: 'Для заявки',
  profile: 'Мой Профиль',
  savedProducts: 'Сохраненные товары',
  policy: 'Политика социальной ответственности',
  more: 'Еще',
  siteMore: 'Разделы сайта',
  // Auth
  login: 'Войти в аккаунт',
  register: 'Зарегистрироваться',
  register2: 'Регистрация',
  isRegister: 'Уже зарегистрирован?',
  noRegister: 'Нет учетной записи?',
  cancel: 'Отмена',
  password: 'Пароль',
  error: 'Вы еще не авторизовались!',
  success: 'Вы успешно вошли в свою учетную запись!',
  success1: 'Ваш аккаунт успешно зарегистрирован!',
  success2: 'Какие возможности это вам дает?',
  success3: 'Теперь вы можете сохранять понравившиеся товары в личную корзину.',
  success4: 'Где я могу просмотреть сохраненные товары?',
  success5: 'Вверху страницы, слева от поля выбора языка, находится панель навигации по сайту. Один щелчок по записи «Сохраненные товары» на этой панели навигации приведет вас на страницу просмотра сохраненных продуктов. Если вы не видите «Сохраненные товары», вы увидите «Разделы сайта» или «Еще». При нажатии на эту запись откроется дополнительная панель навигации, и вы найдете ее там. Если вы по-прежнему не видите «Сохраненные товары», значит, вы либо еще не вошли в систему, либо вышли из системы автоматически. В этом случае вам потребуется повторно войти в аккаунт.',
  success6: 'Зачем нужны Сохраненные товары?',
  success7: 'Вы сможете связаться с нами по поводу сохраненных вами продуктов. То есть вы можете отправлять сохраненные товары напрямую нам на странице «Сохраненные товары». Мы свяжемся с вами по поводу этих продуктов по вашему номеру телефона или электронной почте. Для этого вам необходимо нажать на кнопку «Оставить заявку на сохраненные товары» внизу страницы «Сохраненные товары».',
  success8: 'Закрыть',
  forgotPass: 'Забыл пароль?',
}