export default {
  heading: 'Katalog orqali qidirish',
  heading2: 'Filtr',
  productTitle: 'Mahsulot nomi',
  selectPlaceholder1: 'Kategoriya',
  selectPlaceholder2: "O'ram",
  selectPlaceholder3: 'Tur',
  selectPlaceholder4: 'Brend',
  priceInputPlaceholder1: 'Hajm dan',
  priceInputPlaceholder2: 'Hajm gacha',
  gramInputPlaceholder1: 'Gramm dan',
  gramInputPlaceholder2: 'Gramm gacha',
  searchBtn: 'Qidirish',
}