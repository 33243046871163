import{lazy}from'react'
const Home=lazy(()=>import('./home/Home'))
const News=lazy(()=>import('./news/News'))
const About=lazy(()=>import('./about/About'))
const Likes=lazy(()=>import('./likes/Likes'))
const Brand=lazy(()=>import('./brand/Brand'))
const Brands=lazy(()=>import('./brands/Brands'))
const Profile=lazy(()=>import('./user/Profile'))
const NewsView=lazy(()=>import('./news/NewsView'))
const Catalog=lazy(()=>import('./catalog/Catalog'))
const Contacts=lazy(()=>import('./contacts/Contacts'))
const Catalogs=lazy(()=>import('./catalogs/Catalogs'))
const NotFound=lazy(()=>import('./NotFound/NotFound'))
const ForOrders=lazy(()=>import('./forOrders/ForOrders'))
const ProductView=lazy(()=>import('./productView/ProductView'))
const SearchResult=lazy(()=>import('./searchResult/SearchResult'))
const SavedProducts=lazy(()=>import('./savedProducts/SavedProducts'))
const OrderedProducts=lazy(()=>import('./orderedProducts/OrderedProducts'))
const CatalogsMobileVersion=lazy(()=>import('./catalogs/CatalogsMobileVersion'))
export{Home,News,About,Likes,Brand,Brands,Profile,NewsView,Catalog,Contacts,Catalogs,NotFound,ForOrders,ProductView,SearchResult,SavedProducts,OrderedProducts,CatalogsMobileVersion}