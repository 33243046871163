export default {
  // Globals
  headerPageTitle: 'Asosiy sahifa',
  viewModel: "3D modelni ko'rish",
  addToCart: "Savatga qo'shish",
  openCartSettings: "Savatga qo'shish sozlamalarini ochish",
  closeCartSettings: "Savatga qo'shish sozlamalarini yopish",
  removeToCart: "Savatdan olib tashlash",
  nothingHere: "Bu yerda hech narsa yo'q...",
  // For navbar
  heading: 'Plastik qadoqlash ishlab chiqarish',
  contactsSelect: "Biz bilan bog'lanish",
  about: 'Kompaniya haqida',
  catalog: 'Katalog',
  press: 'Matbuot shakllari',
  pages: 'Sahifalar',
  categories: 'Kategoriyalar',
  cart: 'Savat',
  contact: 'Kontaktlar',
  brands: 'Brendlar',
  forOrders: 'Ariza uchun',
  profile: 'Mening Profilim',
  savedProducts: 'Saqlangan mahsulotlar',
  policy: "Ijtimoiy mas'uliyat siyosati",
  more: "Yana",
  siteMore: "Sayt bo'limlari",
  // Auth
  login: 'Hisobga kirish',
  register: 'Roʻyxatdan oʻtish',
  register2: 'Roʻyxatdan oʻtish',
  isRegister: 'Roʻyxatdan oʻtganmisiz?',
  noRegister: 'Hisob ochilmaganmi?',
  cancel: 'Bekor qilish',
  password: 'Parol',
  error: "Siz hali o'z hisobingizga kirmagansiz!",
  success: "Siz o'z hisobingizga muvaffaqiyatli kirdingiz!",
  success1: "Hisobingiz muvaffaqiyatli ro'yxatdan o'tkazildi!",
  success2: 'Bu sizga qanday imkoniyatlarni beradi?',
  success3: "Endi siz o'zingizga yoqqan mahsulotlarni shaxsiy savatchangizga saqlab qo'yishingiz mumkin.",
  success4: "Saqlangan mahsulotlarni qayerdan ko'rish mumkin?",
  success5: `Sahifa tepasida, tilni tanlash maydonining chap tarafida saytning navigatsiya paneli joylashgan. Shu navigatsiya panelidagi "Saqlangan mahsulotlar" yozuvi ustiga bir marta bosib saqlangan mahsulotlarni ko'rish sahifasiga o'tasiz. Agar "Saqlangan mahsulotlar" yozuvini ko'rmasangiz "Sayt bo'limlari" yoki "Yana" degan yozuv turgan bo'ladi. Shu yozuv ustiga bossangiz qo'shimcha navigatsiya paneli ochiladi va o'sha yerdan topasiz. Agar shunda ham "Saqlangan mahsulotlar" yozuvi umuman bo'lmasa, demak siz o'z hisobingizga hali kirmagansiz yoki hisobingizdan avtomatik tarzda chiqib ketgansiz. Bunday holatda esa qayta hisobga kirish kerak bo'ladi.`,
  success6: 'Saqlangan mahsulotlar nima uchun kerak?',
  success7: `Siz o'zingiz saqlangan mahsulotlar bo'yicha biz bilan bog'lanish imkoniyatiga ega bo'lasiz. Ya'ni, "Saqlangan mahsulotlar" sahifasida o'zingiz saqlagan mahsulotlarni to'g'ridan-to'g'ri bizga yubora olasiz. Biz esa shu mahsulotlar bo'yicha siz bilan telefon raqamingiz yoki elektron pochtangiz orqali bog'lanamiz. Buning uchun esa siz "Saqlangan mahsulotlar" sahifasining pastki qismida "Saqlangan mahsulotlar bo'yicha ariza qoldirish" tugmasini bosishningiz kerak bo'ladi.`,
  success8: 'Yopish',
  forgotPass: 'Parolni unutdingizmi?',
}