export default {
  heading: 'Сохраненные товары',
  heading2: 'Заказанные товары',
  heading3: 'Понравившиеся товары',
  button: 'Оставить заявку на сохраненные товары',
  button2: 'Удаление сохраненных товаров',
  button3: 'Удаление заказанных товаров',
  deletedProducts: ' товар(а/ов), которые вы добавили в корзину, были удалены с сайта.',
  deletedOrderedProductsOne: ' заказанный вами товар был удален с сайта.',
  deletedOrderedProductsMany: ' заказанных вами товар(а/ов) были удалены с сайта.',
}