import { createSlice } from '@reduxjs/toolkit'
import { localeSettings } from '../constants'
import Cookies from 'js-cookie'

// Types
import { ICategoryData, ICompanyNewsData, IPagination, IProduct, ISettings, IUser, IClients, IManufacturer, ISalesNews } from 'types'

interface IStoreCache {
  categories: {
    categories: ICategoryData[] | null
    pagination: IPagination
  }
  products: {
    products: IProduct[] | null
    pagination: IPagination
    isAll: boolean
  }
  manufacturers: {
    manufacturers: IManufacturer[] | null
    pagination: IPagination
  }
  manufacturersBrands: {
    manufacturers: IManufacturer[] | null
    pagination: IPagination
  }
  clients: IClients | []
  salesBanners: {
    salesBanners: ISalesNews[] | null
    pagination: IPagination
  },
  category: ICategoryData | null
  product: IProduct | null
  manufacturer: IManufacturer | null
  companyNews: {
    companyNews: ICompanyNewsData[] | null
    pagination: IPagination
  }
  currentNews: ICompanyNewsData | null
  navCategories: ICategoryData[] | null
}

interface IInitialState {
  isLoading: boolean
  cache: IStoreCache
  user: IUser
  settings: ISettings
}

const initialState: IInitialState = {
  isLoading: true,
  cache: {
    categories: {
      categories: null,
      pagination: {
        page: 0,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    products: {
      products: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
      isAll: false,
    },
    manufacturers: {
      manufacturers: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    manufacturersBrands: {
      manufacturers: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    salesBanners: {
      salesBanners: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    clients: [],
    category: null,
    product: null,
    manufacturer: null,
    companyNews: {
      companyNews: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    currentNews: null,
    navCategories: null,
  },
  user: {
    id: '',
    name: '',
    email: '',
    phone: '',
    surname: '',
    isAuth: false,
    userId: '',
    isFirst: true,
    orderedProducts: [],
    savedProducts: [],
    like: '',
    isEmailVerified: false,
    isPhoneVerified: false,
    userToken: Cookies.get('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$.$U$Z$U$S$E$R$T$O$K$E$N$') || '',
  },
  settings: localeSettings,
}

const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    changeLoading: (state, action) => {
      state.isLoading = action?.payload
    },
    changeCategories: (state, action) => {
      switch (!!action?.payload?.isUpdateble) {
        case true:
          state.cache.categories = { pagination: action?.payload?.pagination, categories: [...(state.cache.categories.categories || []), ...action.payload.categories] }
          break
        case false:
          state.cache.categories = action?.payload
          break
      }
    },
    changeCategory: (state, action) => {
      state.cache.category = action?.payload
    },
    changeNavbarCategory: (state, action) => {
      state.cache.navCategories = action?.payload?.categories
    },
    changeProducts: (state, action) => {
      switch (!!action?.payload?.isUpdateble) {
        case true:
          state.cache.products = { pagination: action?.payload?.pagination, products: [...(state.cache.products.products || []), ...action.payload.products], isAll: action.payload.isAll || false }
          break
        case false:
          state.cache.products = { ...action?.payload, isAll: action.payload.isAll || false }
          break
      }
    },
    changeProduct: (state, action) => {
      state.cache.product = action?.payload
    },
    changeSettings: (state, action) => {
      state.settings = action?.payload

      state.cache.clients = action?.payload?.clients || []
      delete (state.settings as any).clients as any
    },
    changeSalesBanners: (state, action) => {
      state.cache.salesBanners = action?.payload || []
    },
    changeCompanyNews: (state, action) => {
      state.cache.companyNews = action?.payload
    },
    changeCurrentNews: (state, action) => {
      state.cache.currentNews = action?.payload
    },
    changeManufacturers: (state, action) => {
      state.cache.manufacturers = { manufacturers: action?.payload?.brands, pagination: action?.payload?.pagination }
    },
    changeManufacturersBrands: (state, action) => {
      state.cache.manufacturersBrands = { manufacturers: action?.payload?.brands, pagination: action?.payload?.pagination }
    },
    changeManufacturer: (state, action) => {
      state.cache.manufacturer = action?.payload
    },
    userAuth: (state, action) => {
      switch (action?.payload?.type) {
        case 'LOGIN':
          state.user = {
            isAuth: true,
            isFirst: !!action?.payload?.first,
            name: action?.payload?.data?.user?.name,
            phone: action?.payload?.data?.user?.phone,
            email: action?.payload?.data?.user?.email,
            surname: action?.payload?.data?.user?.surname,
            id: action?.payload?.data?.user?.id,
            like: action?.payload?.data?.user?.like,
            userId: action?.payload?.data?.user?._id,
            userToken: action?.payload?.data?.access_token,
            isEmailVerified: action?.payload?.data?.user?.isEmailVerified || false,
            isPhoneVerified: action?.payload?.data?.user?.isPhoneVerified || false,
            orderedProducts: action?.payload?.data?.user?.orderedProducts,
            savedProducts: action?.payload?.data?.user?.savedProducts,
            nowRegistered: !!action?.payload?.nowRegistered,
          }
          Cookies.set('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$.$U$Z$U$S$E$R$T$O$K$E$N$', action?.payload?.data?.access_token, { expires: 10 })
          break

        case 'LOGOUT':
          state.user = {
            isAuth: false,
            isFirst: true,
            id: '',
            name: '',
            phone: '',
            email: '',
            surname: '',
            userId: '',
            userToken: '',
            like: '',
            orderedProducts: [],
            savedProducts: [],
            isEmailVerified: false,
            isPhoneVerified: false,
            nowRegistered: false,
          }
          Cookies.remove('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$.$U$Z$U$S$E$R$T$O$K$E$N$')
          break
      }
    },
    changeUserProfile: (state, action) => {
      state.user = {
        ...state.user,
        name: action?.payload?.user?.name,
        surname: action?.payload?.user?.surname,
        verifyCode: action?.payload?.user?.verifyCode,
      }
    },
    changeOrderedProducts: (state, action) => {
      switch (action?.payload?.type) {
        case 'ADD':
          state.user = { ...state?.user, savedProducts: [], orderedProducts: action?.payload?.orderedProducts }
          break
        case 'REMOVE':
          state.user = { ...state?.user, orderedProducts: action?.payload?.orderedProducts }
          break
      }
    },
    changeSavedProducts: (state, action) => {
      state.user = { ...state?.user, savedProducts: action?.payload?.savedProducts }
    },
    changeLikedProducts: (state, action) => {
      state.user = { ...state?.user, like: action?.payload?.like }
    },
    changeAuth: (state, action) => {
      state.user.isFirst = false
    },
  },
})

export const {
  userAuth,
  changeAuth,
  changeLoading,
  changeProduct,
  changeCategory,
  changeSettings,
  changeProducts,
  changeCategories,
  changeCurrentNews,
  changeCompanyNews,
  changeUserProfile,
  changeManufacturer,
  changeSalesBanners,
  changeLikedProducts,
  changeSavedProducts,
  changeManufacturers,
  changeNavbarCategory,
  changeOrderedProducts,
  changeManufacturersBrands,
} = store?.actions
export default store.reducer