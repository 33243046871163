export default {
  'products': 'Mahsulotlar',
  'seeProducts': "Barcha mahsulolarni ko'rish",
  'accessories-for-bottles': 'Flakonlar uchun mahkamlagichlar',
  'packaging-for-medicine': 'Tibbiyot uchun idishlar',
  'containers-for-food': 'Oziq-ovqat uchun idishlar',
  'canisters': 'Kanistrlar',
  'jars-for-cosmetics': 'Kosmetika uchun flakonlar',
  'packaging-for-household-chemicals': 'Uy uchun kimyoviy moddalari solinadigan idishlar',
  'pet-bottles': 'PET butilkalar',
  'petg-bottles': 'PETG butilkalar',
  'pe,-pp-bottles': 'PE, PP butilkalar',
}