import{lazy}from'react'
const Map=lazy(()=>import('./map/Map'))
const Hero=lazy(()=>import('./hero/Hero'))
const Loader=lazy(()=>import('./loader/Loader'))
const Navbar=lazy(()=>import('./navbar/Navbar'))
const Footer=lazy(()=>import('./footer/Footer'))
const AsyncSelect=lazy(()=>import('./asyncSelect/AsyncSelect'))
const CompanyNews=lazy(()=>import('./companyNews/CompanyNews'))
const ContactForm=lazy(()=>import('./contactForm/ContactForm'))
const MobileNavbar=lazy(()=>import('./mobileNavbar/MobileNavbar'))
const PasswordInput=lazy(()=>import('./passwordInput/PasswordInput'))
export{Map,Hero,Loader,Navbar,Footer,AsyncSelect,CompanyNews,ContactForm,MobileNavbar,PasswordInput}