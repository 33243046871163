export default {
  title: 'Searching results',
  heading: 'Searching results',
  resultsNotFound: 'There are no results for the selected parameters!',
  brand: 'Brand',
  manufacturer: 'Manufacturer',
  standard: 'Throad standard',
  height: 'Height',
  diameter: 'Diameter',
  volume: 'Volume',
  package: 'Package',
  category: 'Category',
  color: 'Type',
  gram: 'Gram',
  price: 'Price',
}