export default {
  heading: 'KONTAKTLAR',
  phone: 'Telefon raqamlar',
  phone2: 'Telefon raqam',
  address: 'MANZILLAR',
  addressHeading: "O'zbekistondagi vakolatxonasi",
  contacHeading: 'Rossiyada ishlab chiqarish',
  addressName: 'Yakkasaroy tumani, Shota Rustaveli 1, 1-chi uy',
  feedback: 'QAYTA ALOQA',
  feedback2: 'Ariza uchun forma',
  name: 'Ism',
  surname: 'Familiya',
  mail: 'E-POCHTA',
  message: 'XABAR',
  password: 'Parol',
  messagePlaceholder: 'Xabaringizni kiriting...',
  submit: "So'rov yuborish",
  submit2: "Jo'natish",
  submit3: 'Отправить заявку',
  save: 'Saqlash',
  saveAll: 'Saqlash',
  sendConfirm: 'Tasdiqlash parolini olish',
  saveEmail: 'Yangi e-pochtani saqlash',
  savePhone: 'Yangi telefon raqamni saqlash',
  currPass: 'Joriy parol',
  newPass: 'Yangi parol',
  confirmPass: 'Parolni tasdiqlang',
  logout: 'Hisobdan Chiqish',
  deleteAcc: "Hisobni o'chirish",
  errorMessage: 'Xatolik yuz berdi, keyinroq qayta urinib koʻring!',
  actions: 'Hisob raqam boshqaruvi',
  changeEmail: "E-pochtani o'zgartirish",
  verifyEmail: 'E-pochtani tasdiqlang',
  changePhone: "Telefon raqamni o'zgartirish",
  verifyPhone: 'Telefon raqamni tasdiqlang',
  generalInfo: "Umumiy ma'lumot",
  verifyCode: 'Tasdiqlash kodi',
  changeLoginPass: "Kirish parolini o'zgartirish",
  enterNewPass: 'Yangi parol kiriting',
  errConfirmPass: 'Tasdiqlash paroli bir xil emas!',
  delAccSure1: "Aniq o'chirmoqchimisiz?",
  delAccSure2: "Yo'q, aniq emas.",
  delAccSure3: 'Ha, albatta!',
  delAccSure4: "Profilingizni o'chirish uchun tasdiqlash kodi emailingizga yuborildi. Yuborilgan kodni terib jo'natsangiz profilingiz butunlayga o'chiriladi.",
  forgotPass1: 'Parolni tiklash uchun hisobingizdagi emailni kiriting.',
  errorMessage2: "Tasdiqlash kodi noto'g'ri!",
}