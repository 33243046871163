export default {
  'products': 'Продукты',
  'seeProducts': 'Посмотреть все продукты',
  'accessories-for-bottles': 'Комплектующие для флаконов',
  'packaging-for-medicine': 'Упаковка для медицины',
  'containers-for-food': 'Флаконы для пищевых продуктов',
  'canisters': 'Канистры',
  'jars-for-cosmetics': 'Баночки для косметики',
  'packaging-for-household-chemicals': 'Упаковка для бытовой химии',
  'pet-bottles': 'ПЭТ Флаконы',
  'petg-bottles': 'ПЭТG Флаконы',
  'pe,-pp-bottles': 'ПЭ, ПП Флаконы',
}