export default {
  heading: 'Search in catalog',
  heading2: 'Filter',
  productTitle: 'Product title',
  selectPlaceholder1: 'Category',
  selectPlaceholder2: 'Package',
  selectPlaceholder3: 'Color',
  selectPlaceholder4: 'Brand',
  priceInputPlaceholder1: 'Volume from',
  priceInputPlaceholder2: 'Volume to',
  gramInputPlaceholder1: 'Gram from',
  gramInputPlaceholder2: 'Gram to',
  searchBtn: 'Search products',
}