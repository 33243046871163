export default {
  heading: 'Saved products',
  heading2: 'Ordered products',
  heading3: 'Liked products',
  button: 'Leave a request for saved products',
  button2: 'Deleting saved products',
  button3: 'Deleting ordered products',
  deletedProducts: ' product(s) you added to your cart have been removed from the site.',
  deletedOrderedProductsOne: ' product you ordered has been removed from the site.',
  deletedOrderedProductsMany: ' products you ordered have been removed from the site.',
}