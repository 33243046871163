import Cookies from 'js-cookie'
import myAxios from 'service/axios'

// Redux store
import { Dispatch } from 'react'
import { userAuth } from 'store/store'
import { AnyAction } from '@reduxjs/toolkit'

export default async function checkTokenValidity(dispatch: Dispatch<AnyAction>) {
  try {
    const response = await myAxios.get('/users/userme')
    dispatch(
      userAuth({
        data: { ...response?.data, access_token: Cookies.get('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$.$U$Z$U$S$E$R$T$O$K$E$N$') || '' },
        type: 'LOGIN',
      }),
    )
  } catch {
    dispatch(userAuth({ data: null, type: 'LOGOUT' }))
  }
}