export default {
  heading: 'Контакты',
  phone: 'Телефоны',
  phone2: 'Телефон',
  address: 'АДРЕСА',
  addressHeading: 'Представительство в Узбекистане',
  contacHeading: 'Производство в РФ',
  addressName: 'Яккасарайский район, ул. Шота Руставели 1, 1-я',
  feedback: 'ОБРАТНАЯ СВЯЗЬ',
  feedback2: 'Форма для заявки',
  name: 'Имя',
  surname: 'Фамилия',
  mail: 'Эл. Почта',
  message: 'СООБЩЕНИЕ',
  password: 'Пароль',
  messagePlaceholder: 'Введите текст сообщения...',
  submit: 'Отправить письмо',
  submit2: 'Отправить',
  submit3: 'Отправить заявку',
  save: 'Сохранить',
  saveAll: 'Сохранить',
  sendConfirm: 'Получить пароль подтверждении',
  saveEmail: 'Сохранить новую э-почту',
  savePhone: 'Сохранить новый номер телефона',
  currPass: 'Текущий пароль',
  newPass: 'Новый пароль',
  confirmPass: 'Подтвердите пароль',
  logout: 'Выйти из профила',
  deleteAcc: 'Удалить профиль',
  errorMessage: 'Ошибка, повторите попытку позже!',
  actions: 'Действия',
  changeEmail: 'Изменить e-mail',
  verifyEmail: 'Подтвердите адрес э-почты',
  changePhone: 'Изменить номер телефона',
  verifyPhone: 'Подтвердите номер телефона',
  generalInfo: 'Общая информация',
  verifyCode: 'Код подтверждения',
  changeLoginPass: 'Изменить пароль для входа',
  enterNewPass: 'Введите новый пароль',
  errConfirmPass: 'Пароль проверки не тот!',
  delAccSure1: 'Вы уверены?',
  delAccSure2: 'Нет, не уверень.',
  delAccSure3: 'Да, конечно!',
  delAccSure4: 'Код подтверждения для удаления вашего профиля был отправлен на вашу электронную почту. Если вы введете присланный код и отправите его, ваш профиль будет полностью удален.',
  forgotPass1: 'Введите адрес э-почты своей учетной записи, чтобы сбросить пароль.',
  errorMessage2: 'Код подтверждения неверен!',
}