export default {
  heading: 'Поиск по каталогу',
  heading2: 'Фильтр',
  productTitle: 'Название продукта',
  selectPlaceholder1: 'Категория',
  selectPlaceholder2: 'Упаковка',
  selectPlaceholder3: 'Тип',
  selectPlaceholder4: 'Бренд',
  priceInputPlaceholder1: 'Объем от',
  priceInputPlaceholder2: 'Объем до',
  gramInputPlaceholder1: 'Грамм от',
  gramInputPlaceholder2: 'Грамм до',
  searchBtn: 'Найти товары',
}