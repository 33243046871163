import { useEffect, useState } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import myAxios from 'service/axios'
import Cookies from 'js-cookie'

// Redux store
import { useDispatch } from 'react-redux'
import { changeCompanyNews, changeLoading, changeNavbarCategory, changeSalesBanners, changeSettings } from 'store/store'

// Routes list
import generateRoutes from './routesLinks'

// Custom hooks
import { useTypedSelector } from 'hooks'

// Helpers
import { checkTokenValidity, hexToHSL, HSLColorVariants } from 'helpers'

// Locale languages
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing Locale translated texts
import TranslationEN from 'Locale/En'
import TranslationRU from 'Locale/Ru'
import TranslationUZ from 'Locale/Uz'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: TranslationEN },
    ru: { translation: TranslationRU },
    uz: { translation: TranslationUZ },
  },
  lng: localStorage.getItem('$abrormarket$.$uz$language$') || 'ru',
  fallbackLng: localStorage.getItem('$abrormarket$.$uz$language$') || 'ru',
  interpolation: {
    escapeValue: false,
  },
})

// Types
import { IProductsResponse, IRootState } from 'types'

// Constants
import { localeSettings } from '../constants'
import { Loader } from 'components'

export default function Router(): JSX.Element {
  const pathname = useLocation()?.pathname
  const dispatch = useDispatch()

  const [isUserChacked, setIsUserChecked] = useState<boolean>(false)

  const {
    user,
    settings,
    isLoading,
    user: { isAuth },
    cache: {
      companyNews,
      navCategories,
      salesBanners: { salesBanners },
    },
  } = useTypedSelector((s: IRootState) => s.store)

  const routes = useRoutes(generateRoutes(isAuth))

  async function getSettings() {
    try {
      const response = await myAxios('/about')
      dispatch(changeSettings(response?.data))
    } catch {
      dispatch(changeSettings(localeSettings))
    }
  }

  async function checking() {
    if (!Cookies.get('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$.$U$Z$U$S$E$R$T$O$K$E$N$')) return setIsUserChecked(true)
    await checkTokenValidity(dispatch)
    setIsUserChecked(true)
  }

  async function getCompanyNews() {
    try {
      const response = await myAxios('/about/news/list?getAll=getAll')
      dispatch(changeCompanyNews(response?.data))
    } catch {
      dispatch(changeCompanyNews(null))
    }
  }

  async function getCategories() {
    try {
      const response = await myAxios('/categories/list?limit=12')
      dispatch(changeNavbarCategory(response?.data))
    } catch {}
  }

  async function getSalesBanners() {
    try {
      const response = await myAxios<IProductsResponse>(`/sales-news/list/?limit=25&page=1`)
      dispatch(changeSalesBanners({ ...response.data }))
    } catch {}
  }

  useEffect(() => {
    if (navigator.onLine) checking() // Checking user token validity
    if (!localStorage?.getItem('$abrormarket$.$uz$language$')) localStorage?.setItem('$abrormarket$.$uz$language$', 'ru')
    if ((settings && settings?.id === 'locale-settings') || !settings || !settings.id) getSettings()
  }, [])

  useEffect(() => {
    if (!salesBanners || salesBanners?.length === 0) getSalesBanners()
  }, [salesBanners])

  useEffect(() => {
    if (!navCategories || navCategories?.length === 0) getCategories()
  }, [navCategories])

  useEffect(() => {
    if ((!companyNews?.companyNews || companyNews?.companyNews?.length === 0) && (pathname === '/' || pathname === '/news')) getCompanyNews()
  }, [pathname])

  useEffect(() => {
    if (settings?.id && isUserChacked && salesBanners && salesBanners?.length > 0) dispatch(changeLoading(false))
  }, [settings, isUserChacked, salesBanners])

  useEffect(() => {
    if (!isAuth) return

    setTimeout(() => {
      if (!(window as any)?.ReplainAPI) return
      ;(window as any)?.ReplainAPI('setFields', { name: `${user?.name || ''} ${user?.surname || ''}`, phone: user?.phone || '', email: user?.email || '' })
    }, 300)
  }, [isAuth, (window as any)?.replainSettings])

  return (
    <>
      {settings?.visualColors && (
        <style>
          {`:root {
          --visualColorMain: ${settings?.visualColors?.main ? hexToHSL(settings?.visualColors?.main) : ''};
          --visualColorSecond: ${settings?.visualColors?.main ? HSLColorVariants(1, hexToHSL(settings?.visualColors?.main)) : ''};
          --visualColorThird: ${settings?.visualColors?.main ? HSLColorVariants(2, hexToHSL(settings?.visualColors?.main)) : ''};
        }`}
        </style>
      )}
      {isLoading && <Loader full />}
      {routes}
    </>
  )
}