import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const BASE_URL = 'https://api.abrormarket.uz/api/'

const myAxios: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
})

myAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const USER_TOKEN = Cookies.get('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$.$U$Z$U$S$E$R$T$O$K$E$N$') || ''
    if (USER_TOKEN) {
      config.headers['Authorization'] = `USER_TOKEN ${USER_TOKEN}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

myAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export default myAxios